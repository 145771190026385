import { useEffect } from "react";

import { ROUTES } from "~app/constants";
import { useLocationQuery } from "~app/hooks/utilHooks";
import { useStripeCheckoutSession } from "~graphql/hooks/stripe";

export function useCheckoutQueryParams() {
    const query = useLocationQuery();

    const checkoutSuccessQueryParam = query.get("checkout_success");
    const sessionId = query.get("session_id");
    const isCheckoutRedirect = !!checkoutSuccessQueryParam;
    const isCheckoutSuccess = checkoutSuccessQueryParam === "true";

    return { isCheckoutRedirect, isCheckoutSuccess, sessionId };
}

export function useStartStripeCheckout({
    successRedirectRoute,
    cancelRedirectRoute,
    showSnackbarOnError,
} = {}) {
    const { getCheckoutSession, checkoutSession, isLoading, error } =
        useStripeCheckoutSession({
            successRedirectRoute,
            cancelRedirectRoute,
            showSnackbarOnError,
        });

    useEffect(() => {
        if (!checkoutSession) return;

        window.location.assign(checkoutSession.session_url);
    }, [checkoutSession]);

    return { startStripeCheckout: getCheckoutSession, isLoading, error };
}

export function useStartJobPublishStripeCheckout({ jobId }) {
    const { startStripeCheckout, isLoading } = useStartStripeCheckout({
        successRedirectRoute: `${ROUTES.JOBS}/${jobId}/confirm-purchase`,
        cancelRedirectRoute: `${ROUTES.JOBS}/${jobId}/edit`,
        showSnackbarOnError: true,
    });

    return { startStripeCheckout, isLoading };
}
