import { gql } from "@apollo/client";

export const GET_STRIPE_CHECKOUT_SESSION = gql`
    query Query(
        $companyId: UUID!
        $stripePriceId: String!
        $successRedirectRoute: String
        $cancelRedirectRoute: String
    ) {
        getStripeCheckoutSession(
            companyId: $companyId
            stripePriceId: $stripePriceId
            successRedirectRoute: $successRedirectRoute
            cancelRedirectRoute: $cancelRedirectRoute
        ) {
            session_id
            session_url
        }
    }
`;

export const GET_STRIPE_PORTAL_SESSION = gql`
    query Query($companyId: UUID!) {
        getStripePortalSession(companyId: $companyId) {
            session_id
            session_url
        }
    }
`;

export const VERIFY_COMPANY_SUBSCRIPTION = gql`
    query Query($sessionId: String!, $companyId: UUID!) {
        verifyCompanySubscription(
            sessionId: $sessionId
            companyId: $companyId
        ) {
            subscription_id
            expiration_expires_at
            is_pay_as_you_go
            features
            feature_groups
        }
    }
`;

// eslint-disable-next-line no-secrets/no-secrets
export const GET_COMPANY_SUBSCRIPTION_PLANS = gql`
    query Query {
        getCompanySubscriptionPlans {
            id
            name
            description
            price
            payment_frequency
            stripe_price_id
            is_default
            created_at
            updated_at
        }
    }
`;
