import { useQuery } from "@apollo/client";

import { updateCompanyInUserCache } from "~graphql/cache/users";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import { useLazyQuery } from "~graphql/hooks/shared";
import {
    GET_STRIPE_CHECKOUT_SESSION,
    GET_STRIPE_PORTAL_SESSION,
    VERIFY_COMPANY_SUBSCRIPTION,
    GET_COMPANY_SUBSCRIPTION_PLANS,
} from "~graphql/queries/stripe";

export function useStripeCheckoutSession({
    successRedirectRoute,
    cancelRedirectRoute,
    showSnackbarOnError,
}) {
    const { companyId } = useCurrentCompany();
    const [getStripeCheckoutSession, { error, loading, data }] = useLazyQuery(
        GET_STRIPE_CHECKOUT_SESSION,
        { fetchPolicy: "no-cache", showSnackbarOnError }
    );

    const getCheckoutSession = ({ stripePriceId }) => {
        return getStripeCheckoutSession({
            variables: {
                companyId,
                stripePriceId,
                successRedirectRoute,
                cancelRedirectRoute,
            },
        });
    };

    const checkoutSession = data?.getStripeCheckoutSession;

    return { error, isLoading: loading, checkoutSession, getCheckoutSession };
}

export function useStripePortalSession() {
    const { companyId, canBillingBeManaged } = useCurrentCompany();

    const [getPortalSession, { error, loading, data }] = useLazyQuery(
        GET_STRIPE_PORTAL_SESSION,
        {
            variables: { companyId },
            fetchPolicy: "no-cache",
            skip: !canBillingBeManaged,
        }
    );

    const portalSession = data?.getStripePortalSession;

    return { error, isLoading: loading, portalSession, getPortalSession };
}

export function useVerifyCompanySubscription({ sessionId }) {
    const { companyId } = useCurrentCompany();

    const { error, loading, client } = useQuery(VERIFY_COMPANY_SUBSCRIPTION, {
        variables: { sessionId, companyId },
        fetchPolicy: "no-cache",
        skip: !sessionId || !companyId,
        onCompleted: (data) => {
            const subscriptionInfo = data?.verifyCompanySubscription;

            updateCompanyInUserCache({
                cache: client.cache,
                data: subscriptionInfo,
                companyId,
            });
        },
    });

    return { error, isLoading: loading };
}

export function useCompanySubscriptionPlans() {
    const { error, loading, data, refetch } = useQuery(
        GET_COMPANY_SUBSCRIPTION_PLANS
    );

    const subscriptionPlans = data?.getCompanySubscriptionPlans;

    return { error, isLoading: loading, subscriptionPlans, refetch };
}
