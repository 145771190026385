import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

export default function ErrorText({ error, variant, message, ...props }) {
    const errorMessage = message || "An error has occured, Please try again";

    if (!error) return null;

    return (
        <Typography {...props} variant={variant} color="error.main">
            {errorMessage}
        </Typography>
    );
}

ErrorText.propTypes = {
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    variant: PropTypes.string,
    message: PropTypes.string,
};
