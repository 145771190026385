import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { hot } from "react-hot-loader/root";

import { TEST_IDS } from "~app/constants";
import { useUserAnalytics } from "~app/hooks/analyticsHooks";
import { useIsAuthenticated } from "~app/hooks/authHooks";
import { useHubspotChat } from "~app/hooks/hubspotHooks";
import { useInvitationCodeParam } from "~app/hooks/searchParamHooks";
import { useNavBarState } from "~app/Providers/NavBarProvider";
import Routes from "~app/Routes";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import { useUser } from "~graphql/hooks/users";
import CompanySubscriptionRedirect from "~pages/PostSignup/CompanySubscriptionRedirect";
import PostSignupCompanyOwner from "~pages/PostSignup/PostSignupCompanyOwner";
import CompanyWorkerMessage from "~shared/CompanyWorkerMessage";
import ErrorMessageWithLogoutButton from "~shared/ErrorMessageWithLogoutButton";
import NavBar from "~shared/NavBar/NavBar";
import SideBar from "~shared/SideBar/SideBar";

export default hot(App);

function App() {
    const { isLoading: isAuthLoading, error: authError } = useAuth0();
    const { isLoading: isUserLoading, error: userError, user } = useUser();
    const {
        isWorker,
        companyId,
        isSubscriptionActive,
        isOwner,
        isLoading: isCompanyLoading,
        isPayAsYouGo,
    } = useCurrentCompany();
    const isAuthenticated = useIsAuthenticated();
    const { isSideBarOpen, setSideBarOpen } = useNavBarState();
    const code = useInvitationCodeParam();

    const anyError = authError || userError;
    const isLoading = isAuthLoading || isUserLoading;

    useHubspotChat();
    useUserAnalytics();

    if (isLoading) {
        return (
            <LoadingContainer data-testid={TEST_IDS.APP_UNAUTHENTICATED}>
                <CircularProgress size={80} thickness={3} />
            </LoadingContainer>
        );
    }

    if (anyError) {
        return (
            <LoadingContainer>
                <ErrorMessageWithLogoutButton
                    data-testid={TEST_IDS.ERROR_WITH_LOGOUT}
                    error={anyError}
                />
            </LoadingContainer>
        );
    }

    if (
        !companyId &&
        isAuthenticated &&
        !isCompanyLoading &&
        !code &&
        !user?.is_internal
    ) {
        // If there isn't a company or we are not being invited to a company, create one so we can redirect to stripe.

        return (
            <AppContainer data-testid={TEST_IDS.APP}>
                <FlexBoxContainer>
                    <AppPage isAuthenticated>
                        {isAuthenticated && <NavBar />}
                        <PostSignupCompanyOwner />
                    </AppPage>
                </FlexBoxContainer>
            </AppContainer>
        );
    }

    if (isWorker) {
        // If logged in user has worker role, only show CompanyWorkerMessage component.
        // They shouldn't be able to access anything else.
        return <CompanyWorkerMessage />;
    }

    if (
        companyId &&
        isAuthenticated &&
        isOwner &&
        !isSubscriptionActive &&
        !isPayAsYouGo
    ) {
        return <CompanySubscriptionRedirect />;
    }

    return (
        <AppContainer data-testid={TEST_IDS.APP}>
            <FlexBoxContainer>
                {isAuthenticated && (
                    <SideBar
                        isOpen={isSideBarOpen}
                        setIsOpen={setSideBarOpen}
                    />
                )}
                <AppPage isAuthenticated>
                    {isAuthenticated && <NavBar />}
                    <Routes />
                </AppPage>
            </FlexBoxContainer>
        </AppContainer>
    );
}

const AppContainer = styled(Box)({
    minHeight: "100vh",
    width: "100%",
});

const AppPage = styled(Box, {
    shouldForwardProp: (prop) => !["isAuthenticated"].includes(prop),
})(({ theme, isAuthenticated }) => ({
    flexGrow: 1,
    minHeight: "100vh",
    height: "100%",
    minWidth: "50%",
    [theme.breakpoints.up("md")]: {
        borderBottomLeftRadius: isAuthenticated && theme.borderRadius.md,
        borderTopLeftRadius: isAuthenticated && theme.borderRadius.md,
    },
    backgroundColor: theme.palette.background.default,
}));

const FlexBoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    backgroundColor: theme.palette.colors.sidebar.background,
}));

const LoadingContainer = styled(Box)(() => ({
    display: "flex",
    minHeight: "100vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
}));
