import { Box, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";

import { useStartStripeCheckout } from "~app/hooks/billingHooks";
import { useCurrentCompanySubscriptionPolling } from "~graphql/hooks/companies";

export default function CompanySubscriptionRedirect() {
    const { startStripeCheckout } = useStartStripeCheckout();
    const { isLoading, isSubscriptionActive } =
        useCurrentCompanySubscriptionPolling();

    useEffect(() => {
        if (!isSubscriptionActive && !isLoading) {
            startStripeCheckout({
                stripePriceId: process.env.STRIPE_JOB_PRICE_ID,
            });
        }
    }, [isSubscriptionActive, startStripeCheckout]);

    return (
        <Container>
            <CircularProgress size={80} thickness={3} />
        </Container>
    );
}

const Container = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
}));
