import { Box, Button, Card, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import Logo, { VARIANTS } from "~shared/Logo/Logo";

export default function SignupCard({
    companyName,
    setCompanyName,
    onSubmit,
    isLoading,
}) {
    return (
        <Container>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <LogoBox>
                    <StyledLogo variant={VARIANTS.BM_APP} />
                </LogoBox>
                <InputTextPrompt>
                    Enter your company name to get started
                </InputTextPrompt>
                <input
                    label="Company Name"
                    placeholder="Company Name"
                    style={{
                        width: "90%",
                        marginBottom: "20px",
                        height: "2.7rem",
                        paddingLeft: "1rem",
                        borderRadius: "25px",
                        borderWidth: "1px",
                        marginTop: "20px",
                        outline: "none",
                    }}
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                />
                <StyledButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    Submit
                </StyledButton>
            </Box>
        </Container>
    );
}

SignupCard.propTypes = {
    companyName: PropTypes.string,
    setCompanyName: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
};

const Container = styled(Card)(() => ({
    padding: "30px",
    width: "100%",
    maxWidth: "400px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    backgroundColor: "white",
    borderRadius: "5px",
    height: "28rem",
}));

const LogoBox = styled(Box)(() => ({
    marginBottom: "20px",
    marginTop: "1rem",
}));

const StyledButton = styled(Button)(() => ({
    borderRadius: "30px",
    height: "3.2rem",
    marginTop: "1rem",
    textTransform: "none",
    fontSize: "16px",
}));

const InputTextPrompt = styled(Typography)(() => ({
    color: "black",
    fontWeight: "500",
    width: "11rem",
    fontSize: "15px",
}));

const StyledLogo = styled(Logo)(() => ({
    height: "7rem",
    width: "7rem",
}));
