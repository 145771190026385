import { Box, styled, Typography } from "@mui/material";
import React, { useState } from "react";

import { useScreenSize } from "~app/hooks/utilHooks";
import { useCreateCompany } from "~graphql/hooks/companies";
import BackgroundImage from "~images/singup-background.png";
import PostSignupCompanyOwnerMobile from "~pages/PostSignup/PostSignupCompanyOwnerMobile";
import SignupCard from "~pages/PostSignup/SignupCard";

export default function PostSignupCompanyOwner() {
    const [companyName, setCompanyName] = useState("");
    const { isSmScreen } = useScreenSize();
    const { createCompany, isLoading, error } = useCreateCompany();

    if (isSmScreen) {
        return (
            <PostSignupCompanyOwnerMobile
                companyName={companyName}
                setCompanyName={setCompanyName}
                onSubmit={() => createCompany({ name: companyName })}
                error={error}
            />
        );
    }

    return (
        <>
            <BackgroundOverlay />
            <Container>
                <LeftColumn>
                    <StyledDescription>
                        Bridging The Gap Between Companies and Skilled Trade
                        Workers
                    </StyledDescription>
                </LeftColumn>
                <RightColumn>
                    <SignupCard
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                        onSubmit={() => createCompany({ name: companyName })}
                        isLoading={isLoading}
                        error={error}
                    />
                </RightColumn>
            </Container>
        </>
    );
}

const BackgroundOverlay = styled(Box)(() => ({
    position: "absolute",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    opacity: 0.65,
    zIndex: 1,
}));

const Container = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    position: "relative",
    width: "100vw",
    zIndex: 2,
}));

const LeftColumn = styled(Box)(() => ({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    paddingLeft: "50px",
    zIndex: 3,
}));

const RightColumn = styled(Box)(() => ({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    paddingRight: "50px",
    zIndex: 3,
}));

const StyledDescription = styled(Typography)(() => ({
    color: "white",
    fontWeight: "800",
    fontSize: "2.6rem",
    lineHeight: "1.1",
}));
