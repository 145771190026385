import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import { useIsDarkMode } from "~app/hooks/utilHooks";
import BmAppLogo from "~images/bm-app-logo.png";
import BNLogo from "~images/BM_Horizontal_Primary_Duotone.png";
import BNLogoWhite from "~images/BM_Horizontal_Primary_White.png";
import WorkOnBadge from "~images/workon-badge.png";
import WorkOnLogoColor from "~images/workon-logo-color.png";

import { TEST_IDS } from "~constants";

export const VARIANTS = {
    BN_LOGO: "bn_logo",
    WORKON_LOGO_COLOR: "workon_logo_color",
    WORKON_BADGE: "workon_badge",
    BM_APP: "bm_app",
    BN_LOGO_WHITE: "bm_logo_white",
};

export default function Logo({ className, variant, noMargin }) {
    const isDarkMode = useIsDarkMode();
    const logoVariant = getLogoVariant({ variant, isDarkMode });
    const testId = getTestId({ variant, isDarkMode });

    return (
        <Image
            className={className}
            variant={variant}
            src={logoVariant}
            data-testid={testId}
            noMargin={noMargin}
        />
    );
}

function getLogoVariant({ variant = "", isDarkMode }) {
    const chooseTheme = isDarkMode ? BNLogo : BNLogoWhite;

    switch (variant) {
        case VARIANTS.BN_LOGO:
            return chooseTheme;
        case VARIANTS.WORKON_BADGE:
            return WorkOnBadge;
        case VARIANTS.WORKON_LOGO_COLOR:
            return WorkOnLogoColor;
        case VARIANTS.BM_APP:
            return BmAppLogo;
        case VARIANTS.BN_LOGO_WHITE:
            return BNLogoWhite;
        default:
            return chooseTheme;
    }
}

function getTestId({ variant = "", isDarkMode }) {
    const theme = isDarkMode ? TEST_IDS.BN_LOGO : TEST_IDS.BN_LOGO_WHITE;

    switch (variant) {
        case VARIANTS.BN_LOGO:
            return theme;
        case VARIANTS.WORKON_BADGE:
            return TEST_IDS.WORKON_BADGE;
        case VARIANTS.WORKON_LOGO_COLOR:
            return TEST_IDS.WORKON_LOGO_COLOR;
        case VARIANTS.BM_APP:
            return TEST_IDS.BM_APP_LOGO;
        default:
            return theme;
    }
}

Logo.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    noMargin: PropTypes.bool,
};

const Image = styled.img(({ variant, noMargin }) => ({
    margin: noMargin ? "" : "auto",
    paddingLeft: variant === VARIANTS.WORKON_BADGE ? "15px" : 0,
    width: "70%",
}));
